@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media screen and (min-width: 500px) {
  .S-D-Profile-pic-container {
    margin-left: 0rem;
  }
  .footer-btn {
    font-size: 1.25rem !important;
  }
}
@media screen and (max-width: 480px) {
  .S-D-Profile-pic-container {
    width: 100px !important;
    height: 100px !important;
    right: 10% !important;
  }
  .blog-content-container img {
    max-width: 250px !important;
    max-height: 250px !important;
    object-fit: contain !important;
    border-radius: 10px;
  }
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
body {
  background-color: rgba(0, 0, 0, 0.87) !important;
  font-family: 'Open Sans', sans-serif;
  color: white !important;
  display: flex !important;
  margin: 0 !important;
  padding: 0 !important;
  justify-content: center;
  overflow-x: hidden;
}
Link,
a {
  text-decoration: none;
}

/* Login Component */
.login-container {
  color: black;
}

/* Single Designer */
.single-D-img-container {
  /* display: flex; */
  position: relative;
  width: 100vw;
  height: 40vh;
}
.single-D-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.S-D-Profile-pic-container {
  display: flex;
  border-radius: 50%;
  width: 125px;
  height: 125px;
  justify-content: center;
  z-index: 100;
  position: absolute;
  top: 41%;
  right: 25%;
  background-color: #333;
}
.S-D-Profile-pic {
  border: 3px solid white;
  display: flex;
  border-radius: 50%;
  width: 100%;
  object-fit: cover;
}

.D-Profile-pic-container {
  display: flex;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  justify-content: center;
  margin-top: -3rem;
  z-index: 100;
}
.D-Profile-pic {
  border: 3px solid white;
  display: flex;
  border-radius: 50%;
  width: 100%;
  object-fit: cover;
}

/* Single COntest */
.S-C-img-container {
  display: flex;
  width: 100%;
  height: 250px;
  margin-bottom: 1rem;
}
.S-C-img {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

/* Footer */

.footer-logo {
  display: flex;
  width: 10rem;
  background-color: #48cfad;
  border-radius: 10px;
}

.footer-btn {
  display: flex;
}
.footer-btn Link,
a {
  text-decoration: none;
  color: white;
  font-size: 1rem;
  font-weight: 300 !important;
}

.MuiPaginationItem-outlined {
  color: white !important;
  border: 1px solid white !important;
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: white !important;
  color: black !important;
}

.MuiPaginationItem-ellipsis {
  border: none !important;
}
.blog-post-container {
  min-height: 70vh !important;
  width: 100vw !important;
  max-width: 1920px;
  margin-top: 4.5rem;
  overflow: hidden;
  color: white;
}
.blog-post-titleImg-container {
  max-height: 30rem;
  height: 300px;
  width: 100% !important;
  max-width: 1920px;
  overflow-y: hidden;
  color: white;
}
.blog-post-titleImg-container img {
  max-width: 1920px;
  width: 100%;
  height: 300px;
  object-fit: cover !important;
}
.blog-content-container img {
  max-width: 500px;
  max-height: 500px;
  object-fit: contain !important;
  border-radius: 10px;
}
